import React from "react"
import ContactPage from "../components/Contact/ContactPage"
import useSiteMetadata from "../components/graphqlQueries/SiteMetadata"
import Seo from "../components/Seo/Seo"

export default function Contact({ location }) {
  const { siteUrl } = useSiteMetadata()
  return (
    <>
      <Seo title="ChemToCode | Contact" pathname={location.pathname} />
      <ContactPage href={`${siteUrl}${location.pathname}`} />
    </>
  )
}
